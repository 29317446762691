import React, { useEffect, useRef,useState } from 'react'
import { Engine, Render, Bodies, World, Composite } from 'matter-js'
import VizSensor from 'react-visibility-sensor';

function Comp (props) {
  const scene = useRef()
  let interval = useRef()
  const isPressed = useRef(false)
  const engine = useRef(Engine.create())
  const [visible, setVisible] = useState(false);
  const [count, setCount] = useState(0);
  

  useEffect(() => {
    // const cw = document.body.clientWidth
    // const ch = document.body.clientHeight

    const cw = scene.current.offsetWidth
    const ch = scene.current.offsetHeight



    const render = Render.create({
      element: scene.current,
      engine: engine.current,
      options: {
        width: cw,
        height: ch,
        wireframes: false,
        background: 'transparent'
      }
    })

    World.add(engine.current.world, [
      Bodies.rectangle(cw / 2, -10, cw, 20, { isStatic: true , render: {
            fillStyle: "rgba(0, 255, 255, 0.0)"
          }}),
      Bodies.rectangle(-10, ch / 2, 20, ch, { isStatic: true , render: {
            fillStyle: "rgba(0, 255, 255, 0.0)"
          }}),
      Bodies.rectangle(cw / 2, ch + 10, cw, 20, { isStatic: true , render: {
            fillStyle: "rgba(0, 255, 255, 0.0)"
          }}),
      Bodies.rectangle(cw + 10, ch / 2, 20, ch, { isStatic: true , render: {
            fillStyle: "rgba(0, 255, 255, 0.0)"
          }})
    ])




    Engine.run(engine.current)
    Render.run(render)

    return () => {
      Render.stop(render)
      World.clear(engine.current.world)
      Engine.clear(engine.current)
      render.canvas.remove()
      render.canvas = null
      render.context = null
      render.textures = {}
    }
  }, [])


  useEffect(function() {
    console.log(count)
        if (count > 50) {
          if (interval.current) {
            clearInterval(interval.current)
          }
        } else {
          
               interval.current = setInterval(() => {
              if (visible && count < 50) {
                  let trap = Bodies.trapezoid(
                  Math.floor(Math.random() * (500 - 10) + 10),
                  10,
                  10 + Math.random() * 10,
                  10 + Math.random() * 10,
                  Math.random(),
                    {
                      mass: 100,
                      restitution: 0.9,
                      friction: 0.1,
                      render: {
                        fillStyle: "rgba(0, 255, 255, 0.2)"
                      }
                    })


                World.add(engine.current.world, [trap])
                setCount(_count => _count + 1)
              }
                
          }, 50);


          
        }

       
      return () => interval && interval.current && clearInterval(interval.current);
       
    


  }, [visible, count]) 

  const handleDown = () => {
    isPressed.current = true
  }

  const handleUp = () => {
    isPressed.current = false
  }

  const handleAddCircle = e => {
    // if (isPressed.current) {
    //     let trap = Bodies.trapezoid(
    //       10,10,
    //       10 + Math.random() * 10,
    //       10 + Math.random() * 10,
    //       1,
    //       {
    //         mass: 10,
    //         restitution: 0.9,
    //         friction: 0.01,
    //         render: {
    //           fillStyle: "rgba(0, 255, 255, 0.4)"
    //         }
    //       })

    //   World.add(engine.current.world, [trap])
    // }
  }

  return (
    <VizSensor
            onChange={(isVisible) => {
              setVisible(isVisible)
            }}
          >
           <div
          onMouseDown={handleDown}
          onMouseUp={handleUp}
          onMouseMove={handleAddCircle}
          style={{position: "absolute", width: "100%", height: "100%", top: 0, left: 0, border: "0px solid black"}}
        >
          <div ref={scene} style={{width: '100%', height: '100%'}} />
        </div>
    </VizSensor>
   
  )
}

export default Comp