import React from "react"

export default class letter extends React.Component {

	onMouseOver = () => {
		if (this.props.popLetter) {
			this.props.popLetter(this.props.index);
		}
	}

	render() {
		if (this.props.visible) {
			let fontSize = 16
			if (this.props.z) {
				fontSize = this.props.z
			}
			return (
				<span 
					className="letterScramble" 
					style={{position: "relative", top:this.props.y, left: this.props.x, fontSize: fontSize}}
					onMouseOver={this.onMouseOver}
				>
				{this.props.letter}
				</span>
			)

		} else {
			
			return (<span style={{top:this.props.y, left: this.props.x}}>&nbsp;</span>)
		}
	}
}