import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from './Components/navBar'
// import idleForestLogo from './idleForestLogo2.jpg'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'

import Carousel from 'react-bootstrap/Carousel'
import Container from 'react-bootstrap/Container'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import LetterScramble from './Components/letterScramble'
import axios from 'axios'
import swal from 'sweetalert'
import Comp from './Components/Matter'
function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function Contact({contactRef}) {
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [emailError, setEmailError] = React.useState('');
  const [messageError, setMessageError] = React.useState('');
  const [isSending, setIsSending] = React.useState(false);
  const [isSent, setIsSent] = React.useState(false);
  return (
            <Form ref={contactRef} style={{padding: 15}}>
              <h4 className="white"> Send me a message </h4>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label style={{color: "white"}}>Email address</Form.Label>
                <br />
                {emailError && <small style={{color:"red"}}>{emailError}</small>}
                <Form.Control value={email} type="email" onChange={(e) => setEmail(e.target.value)} placeholder="name@example.com" />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                {messageError && <small style={{color:"red"}}>{messageError}</small>}
                 <br />
                <Form.Label style={{color: "white"}}>Message</Form.Label>
                <Form.Control value={message} as="textarea" onChange={(e) => setMessage(e.target.value)} rows={3} />
              </Form.Group>

            <Button variant="success" disabled={isSending} style={{marginTop: 15, width: "40%"}} type='button' onClick={() => {
	      if (!validateEmail(email)) {
                setEmailError("I need an email to respond to");
		return ;
              }

              setEmailError('')
              if (!message) {
                setMessageError("I need a message to get a message");
                return
              }
	             setIsSending(true);
              setMessageError('')
              axios.post('https://www.bucheiteric.com/api/sendEmail', {email, message}).then(res => {
          		setIsSent(true)
          		setIsSending(false);
              setMessage("")
              setEmail("")
          		swal({
            			title: "Thanks",
            			text: res.data.message,
            			icon: "success",
          		});
                        }).catch(e => {
          		swal({title: "Something Happened", text: "Could not contact me =(", icon: "warning"});
          	        setIsSending(false);
	             })
            }} >{isSent ? "Thanks, I will Reply soon" : "Send"}</Button>
      </Form>
    )
}
function Resume({resumeRef}) {
  return (
      <div style={{color: "white", textAlign: "left", padding: 15, marginBottom: 50}} ref={resumeRef}>
        <h3 style={{textDecoration:"underline", textAlign: "center"}}>Resume</h3>
        <div><small className="white">bucheiteric@gmail.com</small></div>
        <a href="https://bucheiteric.com/Resume.pdf"><small className="white">View PDF Resume</small></a>
        <div style={{width: "80%", borderBottom: "5px solid black"}}></div>
        <div className="font30">Skills</div>
        <p style={{padding: 15}}>I am a software developer with experience in Javascript, React, PHP, HTML/CSS, SQL, NGINX, C, C++, and C#.</p>
        <hr style={{backgroundColor:"white"}}/>
        <div className="font30">Experience</div>
        <div><span className="white">ASTPOS, Pleasanton CA</span><span> — Software Engineer</span></div>
        <small>2018 - PRESENT</small>
        <ul>
          <li>Build and add new features to our current online ordering system. (PHP, Postgres, Jquery)</li>
          <li>Build and add new features to our analytics / online ordering settings platform.(PHP, Postgres, Jquery)</li>
          <li>Ensure our applications communicate with our merchants POS systems.</li>
          <li>Created, update, and manage our CRM application which tracks our merchants information and internal communication.(ReactJS, Express, Postgres)</li>
          <li>Creating a V2 version of our online ordering system in React and Nodejs</li>
          <li>In charge of server maintenance.</li>
        </ul>
        
        <div><span className="white">JNB Jumps, Tracy CA</span><span> — Manager</span></div>
        <small>2006 - PRESENT</small>
        <ul>
          <li>Family business where I built and maintain a simple application that we use to track customers.</li>
          <li>It was built in Ruby on rails with mysql database.</li>
          <li>Uses customer data to create contracts and delivery routes for our drivers.</li>
          <li>Tracks our current inventory to avoid double bookings.</li>
        </ul>
        <hr style={{backgroundColor:"white"}}/>

        <div className="font30">Education</div>
        <div className="white">42, Fremont CA</div>
        <small>July 2016 - July 2017</small>
        <p style={{padding: 15}}>
          42 is a project based coding school in C, where you work 5 days a week for the first 
          year until you reach a level to get an internship. You are allowed one function to write 
          to standard out to start, and end up creating the basic parts of the standard library. 
          After that, you use your own library to write projects such as ls in the systems branch, 
          algorithm games and shortest paths in the algorithm branch, and wireframe rotation in the 
          graphics branch.
        </p>

      </div>


  )

}

function AboutMe({overViewRef}) {
  return (
     <div className='infoContainer' ref={overViewRef}> 

             <Container style={{marginTop: 50}} >
                <Row>
                  <Col md={{ span: 8}}>
                        <Card style={{ width: '100%', marginLeft: 0 }} className="leftCardContainer">
                        <Comp />
                        <Card.Header style={{height: 50}}> <LetterScramble className="white" str="Welcome"/> </Card.Header>
                              <Card.Body>
                                <Card.Title></Card.Title>
                                <Card.Text>
                                 My Name is Eric and I am A software developer. I also enjoy snowboarding, building and crashing drones, 
                                 and constantly learning new skills along my journey.
                                </Card.Text>
                              </Card.Body>
                            </Card>
                      <br />                  
                  </Col>
                </Row>
              </Container>


              <Container style={{marginTop: 50}} >
                <Row>
                    <Col md={{ span: 8, offset: 4 }}>
                        <Card style={{ width: '100%', marginLeft: 0 }} className="rightCardContainer">
                         <Comp />
                        <Card.Header style={{height: 50, textAlign: "center"}}> <LetterScramble className="white font20" str="Some Skills"/> </Card.Header>
                              <Card.Body>
                                  <ul style={{listStyle: "none"}}>
                                    <li>Node JS</li>
                                    <li>React JS</li>
                                    <li>React Native</li>
                                    <li>PHP</li>
                                    <li>Postgresql</li>
                                    <li>C# (Mainly using Unity)</li>
                                    <li>C</li>
                                    <li>C++</li>
                                    <li>Ruby</li>
                                    <li>Ruby on Rails</li>
                                  </ul>
                              </Card.Body>
                            </Card>
                      <br />
                    </Col>              
                </Row>
              </Container>

              <Container style={{marginTop: 50}} >
                <Row>
                  <Col md={{ span: 8}}>
                        <Card style={{ width: '100%', marginLeft: 0 }} className="leftCardContainer">
                        <Comp />
                        <Card.Header style={{height: 50}}> <LetterScramble className="white" str="About Me"/> </Card.Header>
                              <Card.Body>
                                <Card.Title></Card.Title>
                                <Card.Text>
                                  I am a Software Developer at Amber systems, where I maintain and update our online ordering
                                  and analytics web applications. The applications are written in PHP with Postgresql as the backend.
                                  I also built and maintain our CRM and am in the process of building our online ordering V2, which is in React with nodejs, and postgres.
                                </Card.Text>
                              </Card.Body>
                            </Card>
                      <br />                  
                  </Col>
                </Row>
              </Container>
            </div>


  )
}

function IdleForest({idleForestRef}) {
  return ( 
          <div className='infoContainer' ref={idleForestRef}>
              
              <Container style={{marginTop: 75}} >

                <Row>
                  <Col md={{ span: 10}}>
                  <Card style={{ width: '100%', marginLeft: 0 }} className="leftCardContainer">
                    
                    <Card.Header style={{height: 50}}> <LetterScramble className="white font20" str="IdleForest"/> </Card.Header>
                          <Card.Body>
                            <Card.Title>An Idle game I build</Card.Title>
                            <div className="idleForestContainer">
                              <img className="idleForestLogo" src={process.env.PUBLIC_URL + '/idleForestLogo2.jpg'} width="100%"/> 
                              <Button block className="idleForestButton" variant="success" onClick={() => window.location.replace('/game')}> Play </Button>
                            </div>
                          </Card.Body>
                        </Card>
                  </Col>
                </Row>
              </Container>

               
              <Container style={{marginTop: 75}}>
                <Row>
                    <Col md={{ span: 10, offset: 2 }}>
                        <Card style={{ width: '100%', marginLeft: 0 }} className="rightCardContainer">
                          <Card.Body>
                            <Card.Title>The goal is to level up, while traveling through as many levels as possible.</Card.Title>
                            <div className="idleForestContainer">
                              <img className="idleForestLogo" src={process.env.PUBLIC_URL + '/IdleForestBattle.png'} width="100%"/> 
                            </div>
                          </Card.Body>
                            </Card>
                      <br />
                    </Col>              
                </Row>
              </Container>

              <Container style={{marginTop: 75, marginBottom: 100}} >
                <Row>
                  <Col md={{ span: 10 }}>
                        <Card style={{ width: '100%', marginLeft: 0 }} className="leftCardContainer">
                        <Card.Body>
                            <Card.Title>You can farm for food and craft items from enemy drops</Card.Title>
                            <div className="idleForestContainer">
                              <img className="idleForestLogo" src={process.env.PUBLIC_URL + '/idleForestFarm.png'} width="100%"/> 
                            </div>
                          </Card.Body>
                            </Card>
                      <br />                  
                  </Col>
                </Row>
              </Container>
               <Container style={{marginTop: 50}} >
                <Row>
                  <Col md={{ span: 8}}>
                        <Card style={{ width: '100%', marginLeft: 0 }} className="leftCardContainer">
                        <Comp />
                        <Card.Header style={{height: 50}}> <LetterScramble className="white" str="About This Project"/> </Card.Header>
                              <Card.Body>
                                <Card.Text>
                                  The Project is built from scratch in ReactJS with html canvas, NodeJS (SocketIO), and postgresql to save user information.
                                </Card.Text>
                              </Card.Body>
                            </Card>
                      <br />                  
                  </Col>
                </Row>
              </Container>
              <Container style={{marginTop: 50}} >
                <Row>
                  <Col md={{ span: 8}}>
                        <Card style={{ width: '100%', marginLeft: 0 }} className="rightCardContainer">
                        <Comp />
                        <Card.Header style={{height: 50}}> <LetterScramble className="white" str="Credit"/> </Card.Header>
                              <Card.Body>
                                <Card.Title>Thank You</Card.Title>
                                <Card.Text>
                                 The LPC project at opengameart is where I got pretty much all the images/sprites.
                                 There is credits on the games main page.
                                </Card.Text>
                              </Card.Body>
                            </Card>
                      <br />                  
                  </Col>
                </Row>
              </Container>
            </div>


          
  )
}

function DoggosOnlineOrdering({doggosRef}) {
  return ( 
          <div className='infoContainer' ref={doggosRef}>
              <Container style={{marginTop: 75}} >
                <Row>
                  <Col md={{ span: 10}}>
                  <Card style={{ width: '100%', marginLeft: 0 }} className="leftCardContainer">
                    <Card.Header style={{height: 50}}> <LetterScramble className="white font20" str="Doggos Locos Online"/> </Card.Header>
                          <Card.Body>
                            <Card.Title>An online ordering platform</Card.Title>
                            <div className="idleForestContainer">
                              <img className="idleForestLogo" src={process.env.PUBLIC_URL + '/GoodDoggosCart.png'} width="100%"/> 
                            </div>
                          </Card.Body>
                        </Card>
                  </Col>
                </Row>
              </Container>

               
              <Container style={{marginTop: 75}}>
                <Row>
                    <Col md={{ span: 10, offset: 2 }}>
                        <Card style={{ width: '100%', marginLeft: 0 }} className="rightCardContainer">
                        
                          <Card.Body>
                            <Card.Title>Admins can maintain the items right on the site</Card.Title>
                            <div className="idleForestContainer">
                              <img className="idleForestLogo" src={process.env.PUBLIC_URL + '/DoggosEditItems.png'} width="100%"/> 
                            </div>
                          </Card.Body>
                            </Card>
                      <br />
                    </Col>              
                </Row>
              </Container>

              <Container style={{marginTop: 75, marginBottom: 100}} >
                <Row>
                  <Col md={{ span: 10 }}>
                        <Card style={{ width: '100%', marginLeft: 0 }} className="leftCardContainer">
                        
                        <Card.Body>
                            <Card.Title>Simple Payment Screen</Card.Title>
                            <div className="idleForestContainer">
                              <img className="idleForestLogo" src={process.env.PUBLIC_URL + '/DoggosPayForm.png'} width="100%"/> 
                            </div>
                          </Card.Body>
                            </Card>
                      <br />                  
                  </Col>
                </Row>
              </Container>


              <Container style={{marginBottom: 100}} >
                <Row>
                  <Col md={{ span: 8}}>
                        <Card style={{ width: '100%', marginLeft: 0 }} className="rightCardContainer">
                        <Comp />
                        <Card.Header style={{height: 50}}> <LetterScramble className="white" str="About This Project"/> </Card.Header>
                              <Card.Body>
                                <Card.Text>
                                 Another React and Express project so I had one to call my own.
                                </Card.Text>
                              </Card.Body>
                            </Card>
                      <br />                  
                  </Col>
                </Row>
              </Container>
            </div>


          
  )
}


 
function ImageCarousel() {
  return (
    <div style={{marginTop: 50, marginLeft: 5, width: "100%"}}> 
    <Carousel controls={false} indicators={false} >
      <Carousel.Item>
        <img
          style={{borderRadius: "calc(.25rem - 1px) calc(.25rem - 1px) 0 0"}}
          className="d-block w-100"
          src={process.env.PUBLIC_URL + '/SnowboardOfMe.jpg'}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          style={{borderRadius: "calc(.25rem - 1px) calc(.25rem - 1px) 0 0"}}
          className="d-block w-100"
          src={process.env.PUBLIC_URL + '/MontanaOfMe.jpg'}
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          style={{borderRadius: "calc(.25rem - 1px) calc(.25rem - 1px) 0 0"}}
          className="d-block w-100"
          src={process.env.PUBLIC_URL + '/MyFirstCucumber.jpg'}
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
    </div>
    )
}

function App() {
      let overViewRef = React.useRef(null);
      let resumeRef = React.useRef(null);
      let contactRef = React.useRef(null);
      let idleForestRef = React.useRef(null);
      let doggosRef = React.useRef(null);

      return (
        <div className="App">

          <NavBar 
            overViewRef = {overViewRef}
            resumeRef = {resumeRef}
            contactRef = {contactRef}
            idleForestRef ={idleForestRef}
            doggosRef ={doggosRef}
          />
           
          <div className="AppContainer">

            <div className="imageContainer">
              <ImageCarousel />
            </div>
            <div className="informationContainer">

              <AboutMe overViewRef={overViewRef}/>
              <IdleForest idleForestRef={idleForestRef}/>
              <DoggosOnlineOrdering doggosRef={doggosRef}/>
              <Resume resumeRef={resumeRef}/>
              <Contact contactRef={contactRef} />

            </div>

          </div>
          


        </div>
      );
  }

export default App;



// <iframe className="resume" src="https://docs.google.com/document/d/e/2PACX-1vSKDHyqN0Gq7eN4ZDhfStCfy_P9oC4ZYPyIkvBqnTgjC-A25TJ93q9WdGyH6dw2lm-zYS439iY1R21H/pub?embedded=true"></iframe>
