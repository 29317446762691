import React from "react"
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'

import '../App.css';

import { FaRegArrowAltCircleRight } from 'react-icons/fa';
export default function NavBar({overViewRef, resumeRef, contactRef, idleForestRef, doggosRef}) {
    return(
            <Navbar sticky="top">
            <Navbar.Brand style={{fontFamily: "comic", color: "grey"}}>Eric Bucheit</Navbar.Brand>
               <NavDropdown title="Places" id="nav-dropdown">
                <NavDropdown.Item className="idleForestNavLink" href="http://www.github.com/EricBucheit" >GitHub{'  '} <FaRegArrowAltCircleRight /></NavDropdown.Item>
                <NavDropdown.Item className="idleForestNavLink" href="https://www.linkedin.com/in/eric-bucheit/" >Linked In{'  '} <FaRegArrowAltCircleRight /></NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item className="idleForestNavLink" href="/game" >Idle Forest{'  '} <FaRegArrowAltCircleRight /></NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Things" id="nav-dropdown">
                <NavDropdown.Item className="idleForestNavLink" href="#" onClick={() => {
                  if (overViewRef.current) {
                    overViewRef.current.scrollIntoView()
                  }
                }}>Overview{'  '} <FaRegArrowAltCircleRight /></NavDropdown.Item>
                 <NavDropdown.Item className="idleForestNavLink" href="#" onClick={() => {
                  if (resumeRef.current) {
                    idleForestRef.current.scrollIntoView()
                  }
                }}>Idle Forest Info{'  '} <FaRegArrowAltCircleRight /></NavDropdown.Item>
                 <NavDropdown.Item className="idleForestNavLink" href="#" onClick={() => {
                  if (resumeRef.current) {
                    doggosRef.current.scrollIntoView()
                  }
                }}>Doggos Locos Info{'  '} <FaRegArrowAltCircleRight /></NavDropdown.Item>
                <NavDropdown.Item className="idleForestNavLink" href="#" onClick={() => {
                  if (resumeRef.current) {
                    resumeRef.current.scrollIntoView()

                  }
                }}>Resume{'  '} <FaRegArrowAltCircleRight /></NavDropdown.Item>
                <NavDropdown.Item className="idleForestNavLink" href="#" onClick={() => {
                  if (contactRef.current) {
                    contactRef.current.scrollIntoView()
                  }
                }}>Contact{'  '} <FaRegArrowAltCircleRight /></NavDropdown.Item>
              </NavDropdown>
          </Navbar>
          )
}