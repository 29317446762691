import React from 'react' 
import Letter from "./letter"
import VizSensor from 'react-visibility-sensor';

function RandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export default class LetterScramble extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			str : "",
		}
	}

	componentDidMount() {
		let split = this.props.str.split('');
		split = split.map((letter, index) => {
			return ({
				letter: letter,
				x: RandomInt(-20, 20) * 10,
				y: RandomInt(-20, 20) * 10,
				destx: 0,
				desty: 0,
				exploding: false,
			})
		})
		this.setState({str: split})

		this.update()

	}
	
	
	update = async () => {
		if (this.state.str.length && this.state.visible) {
		    let letters = this.state.str.map((letter, index) => {
			let pos = this.letterToPos(letter.x, letter.y, letter.destx, letter.desty, letter.exploding, index);
				
				if (letter.x === letter.destx && letter.y === letter.desty && letter.exploding) {					
					letter.destx = 0
					letter.desty = 0
					letter.exploding = false
				}

				return ({
					letter: letter.letter,
					x: pos.x,
					y: pos.y,
					destx: letter.destx,
					desty: letter.desty,
					index : index,
					exploding: letter.exploding,
				})
			})
			this.setState({str: letters})
		}
	   this.requestAnimationId = window.requestAnimationFrame(this.update);
	};

	componentWillUnmount() {
	  cancelAnimationFrame(this.requestAnimationId);
	}

	popLetter = (index) => {
		if (!this.props.popOnHover) return false
		let strcp = this.state.str.slice();
		strcp[index].destx = RandomInt(-20, 20) * 10;
		strcp[index].desty = RandomInt(-20, 20) * 10;
		strcp[index].exploding = true
		this.setState({str: strcp});
	}

	reset = () => {
		let split = this.props.str.split('')
		split = split.map((letter, index) => {
			return ({
				letter: letter,
				x: RandomInt(-20, 20) * 10,
				y: RandomInt(-20, 20) * 10,
				destx: 0,
				desty: 0,
				exploding: false,
			})
		})
		this.setState({str: split})
	}

	letterToPos(x, y, destx, desty, exploding, index) {
		if (y > desty) {
			y = y - 5;
		}

		if (y < desty) {
			y = y + 5
		}

		if (x > destx) {
			x = x - 5
		}

		if (x < destx) {
			x = x + 5
		}
		return ({x: x, y: y});
	}

	renderLetters = () => {
		if (!this.state.str.length && !this.state.visible) return false
		return(this.state.str.map((letter, index) => {
			return (
					<Letter 
						key={index} 
						index={index} 
						letter={letter.letter} 
						x={letter.x} 
						y={letter.y}
						z={20}
						popLetter={this.popLetter}
						visible={this.state.visible}
					/>
					)
		}))
	}

	render() {
		return(
			<VizSensor
		        onChange={(isVisible) => {
		        if (!this.state.isVisible) {
		        	if (this.state.str) {
			        	// this.reset()
		        	}
		        }
		          this.setState({visible: isVisible})
		        }}
		      >
		        <div className={this.props.className}>
					{this.renderLetters()}
				</div>
      		</VizSensor>
      			

			
		)
	}
}